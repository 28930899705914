import { useWizardContext, WizardSummary } from 'components/Wizard';
import { LegalEntityAddressInfo } from 'legalEntities/components/LegalEntityAddressInfo';
import { LegalEntityContactInfo } from 'legalEntities/components/LegalEntityContactInfo';
import { LegalEntityPersonalInfo } from 'legalEntities/components/LegalEntityPersonalInfo';
import { IncorporationSummaryCards } from 'summaries/IncorporationSummaryCards/IncorporationSummaryCards';

export const IncorporationSummary = () => {
  const { mutation } = useWizardContext();
  const legalEntityId = mutation.register.legalEntity.id;
  return (
    <WizardSummary>
      <LegalEntityPersonalInfo hasHeaderButton={false} legalEntityId={legalEntityId} />
      <LegalEntityAddressInfo hasHeaderButton={false} legalEntityId={legalEntityId} />
      <LegalEntityContactInfo hasHeaderButton={false} legalEntityId={legalEntityId} />

      <IncorporationSummaryCards mutation={mutation} />
    </WizardSummary>
  );
};
