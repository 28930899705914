import type { RegisterMutation } from '@elseu/sdu-evidend-graphql';
import { MutationType } from '@elseu/sdu-evidend-graphql';
import type { StepProps } from '@elseu/sdu-titan';
import { t } from '@lingui/macro';
import { stepExistsInSteps } from 'components/Wizard/helpers';
import { getLabels } from 'hooks/useLabels';
import type { DeepPartial } from 'react-hook-form';

export const incorporationSteps = {
  LegalEntityCreateSelect: 'bedrijfsgegevens-kiezen',
  LegalEntityDetailsManual: 'bedrijfsgegevens-invoeren',
  LegalEntityDetailsKvk: 'bedrijfsgegevens-ophalen',
  LegalEntityContact: 'bedrijfsgegevens-contact',
  LegalEntityOverview: 'bedrijfsgegevens-overzicht',
  Shares: 'aandelen-uitgeven',
  ShareTypes: 'soorten',
  UploadDocument: 'document-uploaden',
  Summary: 'samenvatting',
} as const;
export const incorporationStepValues = Object.values(incorporationSteps);
export type IncorporationSteps = typeof incorporationSteps;
type IncorporationStepValues = typeof incorporationStepValues;
export type IncorporationStep = IncorporationStepValues[number];

export const isValidStep = (step: string, steps: StepProps[]): boolean => {
  if (!step) return false;
  const isStepInSteps = stepExistsInSteps(steps, step);
  return isStepInSteps;
};

export const determineSteps = (
  mutation: DeepPartial<RegisterMutation>,
  activeStep: IncorporationStep,
) => {
  const { register, type: mutationType } = mutation;
  const legalEntity = register?.legalEntity || {};
  const labels = getLabels('incorporation.steps', legalEntity.isFoundation);

  // Check if incorporation is of type: onboarding
  const isOnboarding = mutationType === MutationType.ONBOARDING;

  // Completion state of legalEntity steps.
  const hasLegalEntityContact =
    typeof legalEntity.phoneNumber === 'string' && typeof legalEntity.emailAddress === 'string';
  const isLegalEntityCompleted = !!legalEntity.name;

  // Completion state of shares steps.
  const isSharesCompleted = !!mutation.incorporation?.length || !!mutation.onboarding?.length;

  // Completion state of document steps.
  const isDeedCompleted = !!mutation.document;

  // Document substeps.
  const uploadDocumentLabel = isOnboarding
    ? labels.uploadDocumentOnboarding
    : labels.uploadDocument;
  const documentLabel = isOnboarding ? labels.documentOnboarding : labels.document;

  // Determine if the manual or kvk legalEntity details step should be shown.
  const hasManualEntry = (() => {
    // Always respect a passed step to indicate manual or kvk entry.
    if (activeStep === incorporationSteps.LegalEntityDetailsManual) return true;
    if (activeStep === incorporationSteps.LegalEntityDetailsKvk) return false;

    // Otherwise determine what step was used.
    const tradeRegisterNumber = legalEntity.tradeRegisterNumber;
    const statutoryName = legalEntity.name;
    return !!(statutoryName && !tradeRegisterNumber);
  })();

  const contactDetailsStep = {
    id: incorporationSteps.LegalEntityContact,
    label: t`Contactgegevens`,
    isCompleted: hasLegalEntityContact,
    isDisabled: !isLegalEntityCompleted,
  };

  const legalEntitySubSteps = [
    {
      id: incorporationSteps.LegalEntityCreateSelect,
      label: t`Keuze gegevensinvoer`,
      isCompleted:
        isLegalEntityCompleted ||
        activeStep === incorporationSteps.LegalEntityDetailsManual ||
        activeStep === incorporationSteps.LegalEntityDetailsKvk,
    },
    hasManualEntry
      ? {
          id: incorporationSteps.LegalEntityDetailsManual,
          label: t`Gegevens invoeren`,
          isCompleted: isLegalEntityCompleted,
        }
      : {
          id: incorporationSteps.LegalEntityDetailsKvk,
          label: t`Gegevens ophalen`,
          isCompleted: isLegalEntityCompleted,
        },
    contactDetailsStep,
  ];

  const hasCompletedLegalEntity = !!legalEntity.name;
  const completedLegalEntityStep = [
    {
      id: incorporationSteps.LegalEntityOverview,
      label: t`Overzicht gegevens`,
      isCompleted: hasCompletedLegalEntity,
    },
    contactDetailsStep,
  ];

  return [
    {
      id: 'bedrijfsgegevens',
      label: t`Rechtspersoon invoeren`,
      isCompleted: isLegalEntityCompleted,
      subSteps: hasCompletedLegalEntity ? completedLegalEntityStep : legalEntitySubSteps,
    },
    {
      id: 'aandelen',
      label: labels.incorporation,
      isCompleted: isSharesCompleted,
      isDisabled: !isLegalEntityCompleted,
      subSteps: [
        {
          id: incorporationSteps.ShareTypes,
          label: labels.shareTypes,
          isCompleted: isSharesCompleted,
        },
        {
          id: incorporationSteps.Shares,
          label: labels.shares,
          isCompleted: isSharesCompleted,
        },
      ],
    },
    {
      id: 'document',
      label: documentLabel,
      isCompleted: isDeedCompleted,
      isDisabled: !isSharesCompleted,
      subSteps: [
        {
          id: incorporationSteps.UploadDocument,
          label: uploadDocumentLabel,
          isCompleted: isDeedCompleted,
        },
      ],
    },
    {
      id: incorporationSteps.Summary,
      label: t`Samenvatting`,
      isDisabled: !isDeedCompleted,
    },
  ];
};
