import type { LegalEntity } from '@elseu/sdu-evidend-graphql';
import { addressFormSchema } from 'forms/address/AddressBaseForm';
import { contactInfoFormSchema } from 'forms/ContactInfoForm';
import { legalEntityGeneralInfoFormSchema } from 'forms/legal-entity/LegalEntityGeneralInfoForm';
import { useLegalEntityForm } from 'forms/legal-entity/useLegalEntityForm';
import { mapLegalEntityToLegalEntityInput } from 'graphql/input-mappers/LegalEntity';
import { useUpdateLegalEntity } from 'legalEntities/hooks/useUpdateLegalEntity';
import type { UpdateLegalEntity_updateLegalEntity } from 'types/graphql/UpdateLegalEntity';
import * as yup from 'yup';

/**
 * Boilerplate to create a form that can handle updating a legal entity and do validation
 * @param props
 * @returns
 */
const useUpdateLegalEntityForm = ({
  legalEntity,
  formSchema = legalEntityGeneralInfoFormSchema
    .concat(yup.object({ visitorAddress: addressFormSchema }))
    .concat(contactInfoFormSchema),
  ...props
}: {
  legalEntity: LegalEntity;
  formSchema?: any;
  onSuccess?: (legalEntity: UpdateLegalEntity_updateLegalEntity) => void;
}) => {
  const [updateLegalEntityQuery] = useUpdateLegalEntity();
  return useLegalEntityForm({
    ...props,
    schema: formSchema,
    defaultValues: legalEntity,
    onSubmit: async (formValues) => {
      const legalEntityInput = mapLegalEntityToLegalEntityInput({
        ...legalEntity,
        ...formValues,
      });
      return updateLegalEntityQuery({
        variables: {
          legalEntityId: legalEntity.id,
          legalEntity: legalEntityInput,
        },
      });
    },
  });
};

export { useUpdateLegalEntityForm };
