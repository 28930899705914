import type { FetchResult } from '@apollo/client';
import type { GraphQLErrors } from '@apollo/client/errors';
import { yupResolver } from '@hookform/resolvers/yup';
import { setFormErrors } from 'forms/helpers/setFormErrors';
import { useGlobalError } from 'hooks/useGlobalError';
import type { DeepPartial, FieldValues } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { CreateLegalEntity } from 'types/graphql/CreateLegalEntity';
import type { LegalEntityQuery } from 'types/graphql/LegalEntityQuery';
import type {
  UpdateLegalEntity,
  UpdateLegalEntity_updateLegalEntity,
} from 'types/graphql/UpdateLegalEntity';
import type { AnyObjectSchema, InferType } from 'yup';

/**
 * Creates a legal entity form with validation
 * @param props
 * @returns
 */
const useLegalEntityForm = <
  LegalEntitySchema extends AnyObjectSchema,
  LegalEntityValues extends FieldValues = InferType<LegalEntitySchema>,
>(props: {
  onSuccess?: (legalEntity: UpdateLegalEntity_updateLegalEntity) => void;
  onFail?: (errors: GraphQLErrors) => void;
  onSubmit: (
    formValues: LegalEntityValues,
  ) => Promise<FetchResult<UpdateLegalEntity | CreateLegalEntity | LegalEntityQuery>>;
  schema: LegalEntitySchema;
  defaultValues?: DeepPartial<InferType<LegalEntitySchema>>;
}) => {
  const { onSuccess, onSubmit, onFail, schema, defaultValues } = props;
  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues,
  });
  const [, setGlobalError] = useGlobalError();
  const { handleSubmit } = form;

  const setValidationErrors = (graphQLErrors: GraphQLErrors) => {
    setFormErrors({
      errors: graphQLErrors,
      form,
      setGlobalError,
    });
    if (onFail) onFail(graphQLErrors);
  };

  const handleLegalEntitySubmit = handleSubmit(async (formValues) => {
    form.reset(formValues); // this will tell the form that the fields are no longer dirty, so we can route away without a warning
    const legalEntity = await onSubmit(formValues)
      .then(({ data, errors }) => {
        if (errors) setValidationErrors(errors);
        if (!data) return;
        if ('updateLegalEntity' in data) return data.updateLegalEntity;
        if ('createLegalEntity' in data) return data.createLegalEntity;
        if ('legalEntity' in data) return data.legalEntity;
        return data;
      })
      .catch((e) => setValidationErrors(e.graphQLErrors || []));
    if (!legalEntity) {
      return;
    }
    if (onSuccess) onSuccess(legalEntity);
  });

  return {
    handleLegalEntitySubmit,
    legalEntityForm: form,
  };
};

export { useLegalEntityForm };
