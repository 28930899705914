import { cloneDeep, forOwn, isArray, isEmpty, isNull, isObject, pull } from 'lodash';

type CleanNull<A> = A extends { [k in keyof A]: A[k] }
  ? {
      [k in keyof A]: CleanNull<null extends A[k] ? Exclude<A[k], null> | undefined : A[k]>;
    }
  : A extends unknown[]
  ? Array<Exclude<CleanNull<A>, null>>
  : A;

/**
 * Recursively prunes null values.
 * Uses generics to keep the type of the object.
 * reference: https://stackoverflow.com/a/26202058
 * @example
 * ```ts
 * type cleanObj = typeof cleanNull<{ a: null | string, b: { c: null | string, d: number | string | null}, e: null, f: 'string' } }>
 * // {
 * //   a: string | undefined;
 * //   b: {
 * //       c: string | undefined;
 * //       d: string | number | undefined;
 * //   };
 * //   e: undefined;
 * //   f: Array<{ c: null | string; d: number | string | null } | null>;
 * // }
 * ```
 */
const cleanNull = <A extends { [k in keyof A]: A[k] } | unknown[]>(values: A): CleanNull<A> => {
  return (function prune(current) {
    forOwn(current, function (value, key) {
      if (isNull(value) || (isObject(value) && isEmpty(prune(value as A))))
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete (current as any)[key];
    });
    // remove any leftover undefined values from the delete
    // operation on an array
    if (isArray(current)) pull(current, undefined);
    return current;
  })(cloneDeep(values)) as CleanNull<A>; // Do not modify the original object, create a clone instead
};

export { cleanNull };
