import { useWizardContext, WizardProvider } from 'components/Wizard';
import { Wizard } from 'components/Wizard/Wizard';
import { ShareTypes } from 'wizardsteps/helpers/ShareTypes';
import { IncorporationSummary } from 'wizardsteps/Incorporation';
import { LegalEntityContact } from 'wizardsteps/Incorporation/LegalEntityContact';
import { LegalEntityCreateSelect } from 'wizardsteps/Incorporation/LegalEntityCreateSelect';
import { LegalEntityDetails } from 'wizardsteps/Incorporation/LegalEntityDetails';
import { IssuanceShares } from 'wizardsteps/Issuance';
import { UploadDocument } from 'wizardsteps/UploadDocument';

import { determineSteps, incorporationSteps, isValidStep } from './IncorporationSteps';

const IncorporationSteps = () => {
  const { step } = useWizardContext();
  switch (step) {
    case incorporationSteps.LegalEntityOverview:
      return <LegalEntityDetails />;
    case incorporationSteps.LegalEntityContact:
      return <LegalEntityContact />;
    case incorporationSteps.ShareTypes:
      return <ShareTypes />;
    case incorporationSteps.Shares:
      return <IssuanceShares />;
    case incorporationSteps.UploadDocument:
      return <UploadDocument />;
    case incorporationSteps.LegalEntityCreateSelect:
      return <LegalEntityCreateSelect />;
    case incorporationSteps.Summary:
      return <IncorporationSummary />;
    default:
      return null;
  }
};

const Incorporation = () => {
  return (
    <WizardProvider determineSteps={determineSteps}>
      <Wizard defaultStep={incorporationSteps.LegalEntityOverview} stepValidator={isValidStep}>
        <IncorporationSteps />
      </Wizard>
    </WizardProvider>
  );
};

export default Incorporation;
