import { Button } from '@elseu/sdu-titan';
import { Trans } from '@lingui/macro';
import { Column, Columns } from 'components/Columns';
import { useWizardContext, WizardFooter, WizardHeader } from 'components/Wizard';
import { ContactInfoForm, contactInfoFormSchema } from 'forms/ContactInfoForm';
import { useUpdateLegalEntityForm } from 'forms/legal-entity/useUpdateLegalEntityForm';
import { useLabels } from 'hooks/useLabels';
import React from 'react';
import { FormProvider } from 'react-hook-form';

const LegalEntityContact = () => {
  const { previousStep, nextStep, visitStep, mutation } = useWizardContext();
  const { register } = mutation;
  const { legalEntity } = register;
  const labels = useLabels('incorporation.legalEntityContact', legalEntity.isFoundation);
  const { legalEntityForm, handleLegalEntitySubmit } = useUpdateLegalEntityForm({
    onSuccess: async () => {
      visitStep(nextStep);
    },
    legalEntity,
    formSchema: contactInfoFormSchema,
  });
  const { isValid, isSubmitting } = legalEntityForm.formState;

  return (
    <FormProvider {...legalEntityForm}>
      <WizardHeader previousStep={previousStep} title={labels.header}>
        {labels.introduction}
      </WizardHeader>

      <Columns count={2}>
        <Column>
          <ContactInfoForm hasColumns={false} />
        </Column>
      </Columns>

      <WizardFooter isLoading={isSubmitting} label={labels.footer}>
        <Button
          isDisabled={!isValid || isSubmitting}
          variant="primary"
          onClick={handleLegalEntitySubmit}
        >
          <Trans>Volgende</Trans>
        </Button>
      </WizardFooter>
    </FormProvider>
  );
};

export { LegalEntityContact };
