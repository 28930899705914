import { useMutation, useQuery } from '@apollo/client';
import type { LegalEntity } from '@elseu/sdu-evidend-graphql';
import { Block, Card, Link, PencilIcon } from '@elseu/sdu-titan';
import { t, Trans } from '@lingui/macro';
import { GeneralInfoContainer } from 'components/GeneralInfoContainer/GeneralInfoContainer';
import type { SidebarFormProps } from 'components/SidebarForm/SidebarForm';
import { SidebarFormContainer, SidebarFormContent } from 'components/SidebarForm/SidebarForm';
import { setFormErrors } from 'forms/helpers/setFormErrors';
import {
  LegalEntityAddressForm,
  legalEntityAddressFormSchema,
} from 'forms/legal-entity/LegalEntityAddressForm';
import { PartyUpdateForm, partyUpdateFormSchema } from 'forms/PartyUpdateForm';
import { mapLegalEntityToLegalEntityInput } from 'graphql/input-mappers/LegalEntity';
import { LEGAL_ENTITY_QUERY, UPDATE_LEGAL_ENTITY } from 'graphql/queries/legalEntity';
import { formatAddressPostalCity, formatAddressStreet } from 'helpers/address';
import { cleanNull } from 'helpers/cleanNull';
import { formatCountryByCode } from 'helpers/formatShares';
import { useGlobalError } from 'hooks/useGlobalError';
import type { ReactNode } from 'react';
import { useState } from 'react';
import type { LegalEntityQuery, LegalEntityQueryVariables } from 'types/graphql/LegalEntityQuery';
import type {
  UpdateLegalEntity,
  UpdateLegalEntityVariables,
} from 'types/graphql/UpdateLegalEntity';

interface AddressField {
  value: 'visitorAddress' | 'correspondenceAddress';
  label: string;
}

interface ILegalEntityAddressInfo {
  canManage?: boolean;
  legalEntityId: string;
  onFormSave?: () => void;
  hasHeaderButton?: boolean;
  headerButton?: ReactNode;
  hasDivider?: boolean;
}
export const LegalEntityAddressInfo = ({
  canManage = false,
  legalEntityId,
  onFormSave,
  hasHeaderButton = true,
  headerButton: headerButtonImport,
  hasDivider = true,
}: ILegalEntityAddressInfo) => {
  const [, setGlobalError] = useGlobalError();
  const [isFormDrawerShown, setFormDrawerShown] = useState<boolean>(false);

  const [updateLegalEntity] = useMutation<UpdateLegalEntity, UpdateLegalEntityVariables>(
    UPDATE_LEGAL_ENTITY,
  );
  const { data: { legalEntity } = {} } = useQuery<LegalEntityQuery, LegalEntityQueryVariables>(
    LEGAL_ENTITY_QUERY,
    {
      variables: {
        legalEntityId,
      },
    },
  );

  if (!legalEntity) return null;

  const headerButton = headerButtonImport || (
    <Link
      prefixAdornment={<PencilIcon />}
      onClick={() => {
        setFormDrawerShown(true);
      }}
    >
      <Trans>Wijzig</Trans>
    </Link>
  );

  const addressFields: AddressField[] = [
    { value: 'visitorAddress', label: t`Bezoekadres` },
    { value: 'correspondenceAddress', label: t`Correspondentieadres` },
  ];
  const contentArray = [];

  for (const addressName of addressFields) {
    const address = legalEntity[addressName.value];
    if (!address?.countryCode) continue;

    const addressStreet = formatAddressStreet(address);
    const addressPostalCity = formatAddressPostalCity(address);
    const addressCountry = formatCountryByCode(address.countryCode);
    contentArray.push({
      label: addressName.label,
      value: (
        <>
          {!!addressStreet && <Block spaceAfter={1}>{addressStreet}</Block>}{' '}
          {!!addressPostalCity && <Block spaceAfter={1}>{addressPostalCity}</Block>}{' '}
          {!!addressCountry && (
            <Block spaceAfter={1}>{formatCountryByCode(address.countryCode)}</Block>
          )}
        </>
      ),
    });
  }

  const defaultValues = {
    visitorAddress: legalEntity.visitorAddress,
    correspondenceAddress: legalEntity.correspondenceAddress,
  };
  const formSchema = legalEntityAddressFormSchema.concat(partyUpdateFormSchema({}));
  const addressInfoForm: SidebarFormProps<typeof formSchema> = {
    header: t`Adresgegevens wijzigen`,
    form: (
      <>
        <Block spaceAfter={6}>
          <LegalEntityAddressForm hasPartyUpdateFields cardBackgroundVariant="grey" />
        </Block>
        <Card backgroundVariant="grey">
          <PartyUpdateForm isFoundation={legalEntity.isFoundation} />
        </Card>
      </>
    ),
    formSchema,
    defaultValues: cleanNull(defaultValues),
    onSubmit: ({ date, reason, ...values }, form) => {
      const legalEntityInput = mapLegalEntityToLegalEntityInput({
        ...legalEntity,
        ...values,
      } as LegalEntity);
      updateLegalEntity({
        variables: {
          legalEntityId: legalEntity.id,
          legalEntity: legalEntityInput,
          effectiveDate: date,
          reason,
        },
        onCompleted: () => {
          if (onFormSave) onFormSave();
          setFormDrawerShown(false);
        },
        onError: ({ graphQLErrors }) => {
          setFormErrors({
            errors: graphQLErrors,
            form,
            setGlobalError,
          });
        },
      });
    },
    setDrawerClosed: () => setFormDrawerShown(false),
  };

  return (
    <>
      <SidebarFormContainer isDrawerShown={isFormDrawerShown}>
        <SidebarFormContent {...addressInfoForm} />
      </SidebarFormContainer>

      <GeneralInfoContainer
        contentArray={contentArray}
        hasDivider={hasDivider}
        header={t`Adresgegevens`}
        headerButton={hasHeaderButton && canManage ? headerButton : undefined}
      />
    </>
  );
};
