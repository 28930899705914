import { Block, Card, Flex, Link, Text } from '@elseu/sdu-titan';
import { t, Trans } from '@lingui/macro';
import { WizardHeader } from 'components/Wizard';
import { useVisitStep } from 'components/Wizard/useVisitStep';
import React from 'react';
import { incorporationSteps } from 'wizards/Incorporation';

export const LegalEntityCreateSelect = () => {
  const visitStep = useVisitStep();

  return (
    <>
      <WizardHeader title={t`Keuze gegevensinvoer`}>
        <Trans>
          Om het register aan te maken in Evidend, voer je eerst de algemene bedrijfsgegevens in. Je
          kunt de gegevens inlezen via de Kamer van Koophandel of zelf de gegevens invullen.
        </Trans>
      </WizardHeader>

      <Text color="grey80" spaceAfter={4} type="labelBold">
        <Trans>Hoe wil je de algemene bedrijfsgegevens invoeren?</Trans>
      </Text>

      <Card spaceAfter={4}>
        <Block>
          <Flex justifyContent="space-between">
            <Trans>Met gegevens van de Kamer van Koophandel</Trans>
            <Link onClick={() => visitStep(incorporationSteps.LegalEntityDetailsKvk)}>
              <Trans>Kiezen</Trans>
            </Link>
          </Flex>
        </Block>
      </Card>

      <Card spaceAfter={4}>
        <Block>
          <Flex justifyContent="space-between">
            <Trans>Handmatig invullen</Trans>
            <Link onClick={() => visitStep(incorporationSteps.LegalEntityDetailsManual)}>
              <Trans>Kiezen</Trans>
            </Link>
          </Flex>
        </Block>
      </Card>
    </>
  );
};
