import { Button } from '@elseu/sdu-titan';
import { t, Trans } from '@lingui/macro';
import { useWizardContext, WizardFooter, WizardHeader } from 'components/Wizard';
import { LegalEntityAddressInfo } from 'legalEntities/components/LegalEntityAddressInfo';
import { LegalEntityPersonalInfo } from 'legalEntities/components/LegalEntityPersonalInfo';
import { useForm } from 'react-hook-form';

export const LegalEntityDetails = () => {
  const { previousStep, nextStep, visitStep, mutation } = useWizardContext();
  const { register } = mutation;
  const { legalEntity } = register;

  const form = useForm();
  const { formState } = form;
  const { isSubmitting } = formState;

  return (
    <>
      <WizardHeader previousStep={previousStep} title={t`Overzicht gegevens`}>
        <Trans>
          Hier zie je de gegevens van de rechtspersoon van deze workflow. Als je de rechtspersoon
          wil aanpassen, kun je de workflow verwijderen en een nieuwe workflow aanmaken.
        </Trans>
      </WizardHeader>

      {legalEntity.name && (
        <>
          <LegalEntityPersonalInfo
            hasDivider={false}
            hasHeaderButton={false}
            legalEntityId={legalEntity.id}
            spaceAfter={6}
          />

          <LegalEntityAddressInfo
            hasDivider={false}
            hasHeaderButton={false}
            legalEntityId={legalEntity.id}
          />

          <WizardFooter isLoading={isSubmitting} label={t`Ga verder om de workflow af te maken.`}>
            <Button onClick={() => visitStep(nextStep)}>
              <Trans>Volgende</Trans>
            </Button>
          </WizardFooter>
        </>
      )}
    </>
  );
};
