import { useMutation, useQuery } from '@apollo/client';
import type { LegalEntity } from '@elseu/sdu-evidend-graphql';
import { Link, PencilIcon } from '@elseu/sdu-titan';
import { t, Trans } from '@lingui/macro';
import { GeneralInfoContainer } from 'components/GeneralInfoContainer/GeneralInfoContainer';
import type { SidebarFormProps } from 'components/SidebarForm/SidebarForm';
import { SidebarFormContainer, SidebarFormContent } from 'components/SidebarForm/SidebarForm';
import type { ContactInfoFormValues } from 'forms/ContactInfoForm';
import { ContactInfoForm, ContactInfoFormSchema } from 'forms/ContactInfoForm';
import { setFormErrors } from 'forms/helpers/setFormErrors';
import { mapLegalEntityToLegalEntityInput } from 'graphql/input-mappers/LegalEntity';
import { LEGAL_ENTITY_QUERY, UPDATE_LEGAL_ENTITY } from 'graphql/queries/legalEntity';
import { useGlobalError } from 'hooks/useGlobalError';
import React, { useState } from 'react';
import type { LegalEntityQuery, LegalEntityQueryVariables } from 'types/graphql/LegalEntityQuery';
import type {
  UpdateLegalEntity,
  UpdateLegalEntityVariables,
} from 'types/graphql/UpdateLegalEntity';

interface ILegalEntityContactInfo {
  canManage?: boolean;
  legalEntityId: string;
  onFormSave?: () => void;
  hasHeaderButton?: boolean;
  headerButton?: React.ReactNode;
  hasDivider?: boolean;
}
export const LegalEntityContactInfo = ({
  canManage = false,
  legalEntityId,
  onFormSave,
  hasHeaderButton = true,
  headerButton: headerButtonImport,
  hasDivider = true,
}: ILegalEntityContactInfo) => {
  const [, setGlobalError] = useGlobalError();
  const [isFormDrawerShown, setFormDrawerShown] = useState<boolean>(false);

  const [updateLegalEntity] = useMutation<UpdateLegalEntity, UpdateLegalEntityVariables>(
    UPDATE_LEGAL_ENTITY,
  );
  const { data: { legalEntity } = {} } = useQuery<LegalEntityQuery, LegalEntityQueryVariables>(
    LEGAL_ENTITY_QUERY,
    {
      variables: {
        legalEntityId,
      },
    },
  );

  if (!legalEntity) return null;

  const headerButton = headerButtonImport || (
    <Link
      prefixAdornment={<PencilIcon />}
      onClick={() => {
        setFormDrawerShown(true);
      }}
    >
      <Trans>Wijzig</Trans>
    </Link>
  );

  const contentArray = [
    {
      label: t`E-mailadres`,
      value: legalEntity.emailAddress ? (
        <Link to={`mailto:${legalEntity.emailAddress}`}>{legalEntity.emailAddress}</Link>
      ) : (
        t`Onbekend`
      ),
    },
    {
      label: t`Telefoonnummer`,
      value: legalEntity.phoneNumber ? (
        <Link to={`call:${legalEntity.phoneNumber}`}>{legalEntity.phoneNumber}</Link>
      ) : (
        t`Onbekend`
      ),
    },
  ];

  const defaultValues: ContactInfoFormValues = {
    emailAddress: legalEntity.emailAddress,
    phoneNumber: legalEntity.phoneNumber,
  };
  const contactInfoForm: SidebarFormProps<typeof ContactInfoFormSchema> = {
    header: t`Contactgegevens wijzigen`,
    form: <ContactInfoForm hasColumns={false} />,
    formSchema: ContactInfoFormSchema,
    defaultValues,
    onSubmit: (values, form) => {
      const legalEntityInput = mapLegalEntityToLegalEntityInput({
        ...legalEntity,
        ...values,
      } as LegalEntity);
      updateLegalEntity({
        variables: {
          legalEntityId: legalEntity.id,
          legalEntity: legalEntityInput,
        },
        onCompleted: () => {
          if (onFormSave) onFormSave();
          setFormDrawerShown(false);
        },
        onError: ({ graphQLErrors }) => {
          setFormErrors({
            errors: graphQLErrors,
            form,
            setGlobalError,
          });
        },
      });
    },
    setDrawerClosed: () => setFormDrawerShown(false),
  };

  return (
    <>
      <SidebarFormContainer isDrawerShown={isFormDrawerShown}>
        <SidebarFormContent {...contactInfoForm} />
      </SidebarFormContainer>

      <GeneralInfoContainer
        contentArray={contentArray}
        hasDivider={hasDivider}
        header={t`Contactgegevens`}
        headerButton={hasHeaderButton && canManage ? headerButton : undefined}
      />
    </>
  );
};
